import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

// theme
import theme from "../theme";

// clients
import FlightKingdom from "../assets/flightkingdom.jpeg";
import FlightAndMore from "../assets/flightandmore.jpeg";
import BrothersEstates from "../assets/brothers-estates.jpeg";
import NewHorizonCare from "../assets/newhorizoncare.jpeg";
import TravelServe from "../assets/travelserve.jpeg";
import WeaverRose from "../assets/weaverrose.jpeg";

// clients
const clients = [
  {
    id: "1",
    image: FlightKingdom,
    name: "Flight Kingdom",
    website: "www.flightkingdom.com",
  },
  {
    id: "2",
    image: FlightAndMore,
    name: "Flight And More",
    website: "www.flightandmore.co.uk",
  },
  {
    id: "3",
    image: BrothersEstates,
    name: "Brothers Estates",
    website: "www.brothers-estates.co.uk",
  },
  {
    id: "4",
    image: NewHorizonCare,
    name: "New Horizon Care",
    website: "www.newhorizoncare.co.uk",
  },
  {
    id: "5",
    image: TravelServe,
    name: "Travel Serve",
    website: "www.travelserve.net",
  },
  {
    id: "6",
    image: WeaverRose,
    name: "Weaver Rose",
    website: "www.weaverrose.uk",
  },
];

const About = ({ id }) => {
  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 3,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          Our Portfolio
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 4 }}
          gutterBottom
        >
          Our Featured Clients
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 6 }} gutterBottom>
          We continually strive to stay ahead of the retail learning curve so
          that your online business presents the smartest solutions for your
          customers. Our focus and leadership in best Internet practices leads
          to one thing for your organization: significant return on investment.
          We've done the homework and the 'dirty work'. Our repository of
          knowledge and our flexibility with that knowledge is focused only on
          you and your relationship with your customers.
        </Typography>
      </Box>
      <Grid container direction="row" justifyContent="center" spacing={4}>
        {clients.map((client) => (
          <Grid item xs={6} md={4} key={client.id}>
            <Card sx={{ maxWidth: 345 }} elevation={3}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="250"
                  image={client.image}
                  alt={client.name}
                  sx={{
                    objectFit: "cover",
                    opacity: "0.8",
                    boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.2)",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {client.name}
                  </Typography>
                  <Typography variant="body2" color="primary.main">
                    {client.website}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default About;
