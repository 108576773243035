// config
export const config = {
  dev: {
    name: "Maniva",
    company: "Geektive Limited",
    website: "https://www.geektive.com/",
    version: "0.1.0",
  },
  api: {
    baseURL: "http://127.0.0.1:5000",
  },
  client: {
    name: "Geektive Limited",
    website: "https://www.geektive.com/",
  },
};
