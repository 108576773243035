import React from "react";
import { Container, Box, Grid, Typography, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

// config
import { config } from "../config";

// material ui styled
import { styled } from "@mui/material/styles";

// theme
import theme from "../theme";

// assets
import AboutImgSrc from "../assets/about.jpeg";

// Logo
const AboutImg = styled("img")(({ theme }) => ({
  minWidth: "100%",
  height: "auto",
  padding: 0,
  margin: 0,
  borderRadius: "6px",
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&.Mui-selected": {
      color: "primary",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

// data
const data = [
  {
    id: "1",
    title: "Who We Are?",
    description:
      "We have been delivering successful, cost-effective and easy-to-use ecommerce and CRM systems, helping many businesses take advantage of using the Internet as a strategic business tool. Our approach is to understand your business, your customers, and your market place, prior to making any recommendations. Whatever your ideas or aspirations for an ecommerce solution, ecommerce web site or web portal software solution, we can help.",
  },
  {
    id: "2",
    title: "Why Choose Us?",
    description:
      "Geektive is also a consulting firm focused on serving the comprehensive needs of businesses in the full range of the business cycle. With a core staff of experienced professionals and a team approach to most consulting projects, We will be able to offer a more balanced quality service than many of its competitors.",
  },
  {
    id: "3",
    title: "What We Offer?",
    description:
      "Our services consist of business management, information technology and financial consultancy specializing in implementing well proven management profitability and organizational improvements. At Geektive we understand the many challenges facing businesses in today's fast-paced and competitive climate.",
  },
];

const About = ({ id }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 3,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          About Us
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 4 }}
          gutterBottom
        >
          A Creative Digital Company
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 6 }} gutterBottom>
          We design brand platforms, driving growth in digital culture.
        </Typography>
      </Box>
      <Grid container direction="row" justifyContent="center" spacing={4}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AboutImg src={AboutImgSrc} alt={config.client.name} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TabContext value={value}>
            <Box
              sx={{
                borderRadius: "0px",
                px: 2,
                py: 1,
              }}
            >
              <TabList onChange={handleChange}>
                {data.map((service) => (
                  <StyledTab
                    key={service.id}
                    label={service.title}
                    value={service.id}
                  />
                ))}
              </TabList>
            </Box>
            {data.map((service) => (
              <TabPanel key={service.id} value={service.id}>
                {service.description}
              </TabPanel>
            ))}
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
