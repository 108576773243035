import React from "react";
import { Container, Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

// config
import { config } from "../config";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        backgroundColor: grey[200],
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ my: 8 }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography align="center" variant="body2" color="text.secondary">
              © Copyright {new Date().getFullYear()}, {config.client.name}. All
              Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
