import React from "react";
import { Link } from "react-scroll";
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

// config
import { config } from "../config";

// material ui styled
import { styled } from "@mui/material/styles";

// material icons
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

// assets
import HeroImgSrc from "../assets/hero.jpeg";

import Video from "../components/Video";

// Logo
const HeroImg = styled("img")(({ theme }) => ({
  minWidth: "100%",
  height: "auto",
  padding: 0,
  margin: 0,
  borderRadius: "6px",
}));

const Hero = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        backgroundColor: grey[200],
        py: 12,
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 900,
                textAlign: { xs: "center", md: "left" },
              }}
              gutterBottom
            >
              A Creative Digital Social Media Marketing Company.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: { xs: "center", md: "left" }, mb: { xs: 4 } }}
            >
              We are a digital social media marketing agency that helps brands
              to achieve their business outcomes. We see technology as a tool to
              create amazing things. Drive Social Marketing success with
              Geektive integrated customer hub.
            </Typography>
            <Stack
              direction="row"
              justifyContent={isSmallScreen ? "center" : "start"}
              alignItems={isSmallScreen ? "center" : "start"}
              spacing={2.5}
              sx={{ mt: 3 }}
            >
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <Button variant="contained" color="primary">
                  GET STARTED
                </Button>
              </Link>
              <Video
                startIcon={<PlayCircleFilledWhiteIcon />}
                btnText="Watch Intro"
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HeroImg src={HeroImgSrc} alt={config.client.name} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
