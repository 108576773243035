import React from "react";

// components
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import Services from "../components/Services";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import BackToTop from "../components/BackToTop";

const Home = () => {
  return (
    <React.Fragment>
      <NavBar />
      <Hero />
      <About id="about" />
      <Services id="services" />
      <Portfolio id="portfolio" />
      <Contact id="contact" />
      <Footer />
      <BackToTop />
    </React.Fragment>
  );
};

export default Home;
