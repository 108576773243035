import * as React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { grey } from "@mui/material/colors";

// config
import { config } from "../config";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  Tooltip,
  MenuItem,
  Link as ButtonLink,
} from "@mui/material";

// material ui icons
import MenuIcon from "@mui/icons-material/Menu";
import LockIcon from "@mui/icons-material/Lock";
import CircleIcon from "@mui/icons-material/Circle";
import LoginIcon from "@mui/icons-material/Login";

// material ui styled
import { styled } from "@mui/material/styles";

// assets
import LogoImg from "../assets/logo.jpeg";

// Logo
const Logo = styled("img")(({ theme }) => ({
  width: "250px",
}));

const pages = ["About", "Services", "Portfolio", "Contact"];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        borderBottom: `1px solid ${grey[200]}`,
        boxShadow: `0px 0px 4px ${grey[200]}`,
      }}
      position="sticky"
      color="inherit"
    >
      <Container maxWidth="lg" sx={{ my: 1.5 }}>
        <Toolbar disableGutters>
          {/* logo:md */}
          <Logo
            src={LogoImg}
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 5,
              cursor: "pointer",
            }}
            alt={config.client.name}
            onClick={scrollToTop}
          />
          {/* logo:md */}

          {/* menu:xs */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page}>
                  <Link
                    activeClass="active"
                    to={page.toLowerCase()}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* menu:xs */}

          {/* logo:xs */}
          <Box
            sx={{
              width: "100%",
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Logo
              src={LogoImg}
              sx={{ width: "180px", cursor: "pointer" }}
              alt={config.client.name}
              onClick={scrollToTop}
            />
          </Box>
          {/* logo:xs */}

          {/* menu:md */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Link
                key={page}
                activeClass="active"
                to={page.toLowerCase()}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                <Button
                  size="large"
                  endIcon={<CircleIcon sx={{ fontSize: "13px !important" }} />}
                  onClick={handleCloseNavMenu}
                  sx={{ color: "primary", mr: 1.5 }}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>
          {/* menu:md */}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Client Login">
              <ButtonLink underline="none" href="/login" target="_blank">
                <Button
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                  variant="outlined"
                  startIcon={<LockIcon />}
                  endIcon={<CircleIcon sx={{ fontSize: "13px !important" }} />}
                >
                  Login
                </Button>

                <IconButton
                  sx={{
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <LoginIcon />
                </IconButton>
              </ButtonLink>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
