import React from "react";
import {
  Container,
  Box,
  Grid,
  Typography,
  Paper,
  IconButton,
  Icon,
  Stack,
} from "@mui/material";

// theme
import theme from "../theme";

// data
const services = [
  {
    id: 1,
    icon: "design_services",
    title: "Creative Design",
    description:
      "We design your website for the responsive web in minutes for beautifully responsive pages on desktops, tablets and smartphones.",
  },
  {
    id: 2,
    icon: "shopping_cart",
    title: "E-Commerce",
    description:
      "All of our ecommerce and web site solutions are expertly designed to provide a compelling user experience, leading to increased visitors.",
  },
  {
    id: 3,
    icon: "dvr",
    title: "Social Media Marketing",
    description:
      "We can make Social Media Marketing work for your business. Drive Social Marketing success with Geektive integrated customer hub.",
  },
  {
    id: 4,
    icon: "phone_iphone",
    title: "Mobile Apps",
    description:
      "We can design and develop the smartphone application you imagine, using the latest smartphone technologies.",
  },
  {
    id: 5,
    icon: "query_stats",
    title: "Search Engine Optimization",
    description:
      "We have many years of experience in the digital marketing industry, which means we’ve been doing it ever since it became an industry.",
  },
  {
    id: 6,
    icon: "group_work",
    title: "IT Consultancy",
    description:
      "Our services consist of business management, e-commerce and financial consultancy specializing in implementing well proven systems.",
  },
];

const Services = ({ id }) => {
  return (
    <Container maxWidth="lg" sx={{ my: 11 }} id={id}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          sx={{
            px: 5,
            py: 1,
            mb: 2,
            color: theme.palette.primary.main,
            fontWeight: 600,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: "18px",
          }}
        >
          Services
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ fontWeight: 900, mb: 3 }}
          gutterBottom
        >
          Our Best Services
        </Typography>
        <Typography align="center" variant="body1" sx={{ mb: 5 }} gutterBottom>
          Geektive is a digital marketing agency with a strong heritage in
          search that builds Connected Brands.
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        {services.map((service) => (
          <Grid key={service.id} item xs={12} md={4}>
            <Paper
              variant="outlined"
              sx={{
                px: 4,
                py: 5,
                "&:hover": {
                  boxShadow: `0px 2px 4px rgba(40, 41, 61, 0.04),
                  0px 8px 16px rgba(96, 97, 112, 0.16)`,
                  transition: "all .3s ease-out 0s",
                },
              }}
            >
              <Stack spacing={2}>
                <Box>
                  <IconButton
                    disableRipple
                    color="primary"
                    size="large"
                    sx={{ border: "2px solid", cursor: "auto" }}
                  >
                    <Icon sx={{ fontSize: "40px" }}>{service.icon}</Icon>
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6">{service.title}</Typography>
                </Box>
                <Box>
                  <Typography>{service.description}</Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
