import { createTheme } from "@mui/material/styles";
import { orange, grey } from "@mui/material/colors";

const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: orange[800],
    },
    secondary: {
      main: grey[500],
    },
  },
  typography: {
    h3: {
      fontSize: "3rem",
      [breakpoints.down("md")]: {
        fontSize: "2rem",
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
