import React from "react";
import { Map as MyMap, Marker } from "pigeon-maps";

// theme
import theme from "../theme";

const Map = () => {
  const center = [51.49932, -0.39139];

  return (
    <MyMap
      height={500}
      defaultCenter={center}
      defaultZoom={16}
      metaWheelZoom={true}
    >
      <Marker width={50} anchor={center} color={theme.palette.primary.main} />
    </MyMap>
  );
};

export default Map;
